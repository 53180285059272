<template>
  <!-- Brand logo-->
  <b-link
    class="brand-logo"
    to="/"
  >
    <b-img
      :src="appLogoImage"
      alt="logo"
    />
    <h2 class="brand-text text-primary ml-1 mb-0">
      {{ appName }}
    </h2>
  </b-link>
  <!-- /Brand logo-->
</template>

<script>import {
  BLink, BImg,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  name: 'Logo',
  components: {
    BLink,
    BImg,
  },
  computed: {
    appLogoImage() {
      const { appLogoImage } = $themeConfig.app
      return appLogoImage
    },
    appName() {
      const { appName } = $themeConfig.app
      return appName
    },
  },
}
</script>

<style lang="scss" scoped>
.brand-logo {
  display: flex;
  img {
    width: 42px;
    max-width: none;
  }
}
</style>
